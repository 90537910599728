/*
================================================
2. Header CSS
================================================
*/
/** index Header **/
.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9;
  transition: 0.5s;
  padding-top: 10px;
}

@keyframes headerSticky {
  to {
    transform: translateY(0);
  }
}

.header.sticky {
  transform: translateY(-100%);
  position: fixed;
  padding-top: 0;
  background-color: #fff;
  box-shadow: 0 0 10px #ddd;
  animation: headerSticky 0.3s forwards;
}

.navbar {
  transition: 0.5s;
}

.header-logo {
  display: flex;
  align-items: center;
}

.header-logo img {
  width: 36px;
  padding-right: 0.5em;
}

.header-logo-text {
  color: #302D3A;
  font-size: 28px;
  font-family: Poppins-Medium, Poppins;
  font-weight: 500;
  letter-spacing: 0.03em;
}

.navbar-nav {
  margin: 0 auto;
}

.nav-link {
  font-size: 16px;
  color: #5F5982;
  transition: 0.3s;
}

.dropdown-item {
  text-transform: capitalize;
}

.home-img img {
  height: 220px;
  width: 220px;
}

.navbar-nav .nav-link .dropdown-icon {
  transition: 0.2s;
}

.header-svg-fill {
  fill: #5F5982;
}

.header-light .header-svg-fill {
  fill: #fff;
}

.header-light .header-logo .header-logo-text {
  color: #fff;
}

.header-light .nav-link {
  color: #fff;
}

.header-light.sticky .header-logo .header-logo-text,
.header-light[style="background-color: #fff;"] .header-logo-text{
  color: #302D3A;
}

.header-light.sticky .nav-link,
.header-light[style="background-color: #fff;"] .nav-link {
  color: #5F5982;
}

.header-light.sticky .header-svg-fill,
.header-light[style="background-color: #fff;"] .header-svg-fill {
  fill: #5F5982;
}

.dropdown-item {
  font-size: 15px;
  color: #5F5982;
}

.dropdown-toggle::after {
  display: none;
}

.navbar-toggler, .navbar-toggler::after,
.navbar-toggler::before {
  height: 3px;
  width: 30px;
  border-radius: 10px;
  position: relative;
  padding: 0;
  border: 0;
  top: 0;
  left: 0;
  transition: 0.5s;
  background-color: transparent;
}

.navbar-toggler::after,
.navbar-toggler::before {
  content: "";
  opacity: 1;
  position: absolute;
  background-color: #F56962;
}

.navbar-toggler::after {
  transform: rotate(45deg);
}


.navbar-toggler::before {
  transform: rotate(-45deg);
}

.navbar-toggler:focus, .navbar-toggler:focus::after,
.navbar-toggler:focus::before {
  box-shadow: none;
}

.navbar-toggler.collapsed, .collapsed.navbar-toggler::after,
.collapsed.navbar-toggler::before {
  opacity: 1;
  background-color: #F56962;
}

.collapsed.navbar-toggler::after,
.collapsed.navbar-toggler::before {
  top: -7px;
  transform: none;
  transform: rotate(0);
}


.collapsed.navbar-toggler::before {
  top: 7px;
}

@media only screen and (min-width: 992px) {
  .header .mega-nav-item {
    position: static;
  }
  .header .mega-nav-item .dropdown-menu {
    margin-top: -20px !important;
  }
  .header .dropdown-menu {
    min-width: 330px;
    display: block;
    box-shadow: 0 5px 40px rgba(0, 0, 0, 0.15);
    top: 100%;
    width: 100%;
    opacity: 0;
    z-index: -9;
    visibility: hidden;
    overflow: hidden;
    margin-top: 0;
  }
  .header .nav-item.dropdown:hover .dropdown-icon {
    transform: rotate(-180deg);
  }
  .header .nav-item.dropdown:hover .dropdown-menu,
  .header .dropdown-menu:hover {
    z-index: 9;
    opacity: 1;
    visibility: visible;
    top: 100%;
    transition: all .2s ease-out;
  }
  .header .nav-item.dropdown:hover .dropdown-menu .dropdown-item,
  .header .dropdown-menu:hover .dropdown-item {
    transition: all .2s ease-out 0s;
  }
  .header .dropdown-item {
    transition: all 0s ease-out;
    box-shadow: 0px 30px 60px -10px rgba(0, 0, 0, 0.1);
  }
  .header .dropdown-item:hover {
    box-shadow: 0px 30px 60px 0px rgba(0, 0, 0, 0.1);
    transform: scale(1.05);
  }
}

/*
================================================
3. Hero Banner version3 CSS
================================================
*/
.hero-banner-v3 {
  padding-top: 130px;
  padding-bottom: 120px;
  min-height: 80vh;
  background-image: url("../../assets/images/hero-banner3.png");
  background-position: center;
}

.hero-banner-v3::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

/*
================================================
4. Skilify Info Card css
================================================
*/
.skilify-info-sec {
  padding-top: 40px;
}

.skilify-info-points {
  top: -80px;
  box-shadow: 0 0 30px -15px rgba(0, 0, 0, 0.4);
}

.skilify-info-point-img {
  border: 2px solid #33333D;
  border-radius: 50%;
}

.skilify-info-point-img img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  padding: 8px;
  margin: 8px;
  background-color: #33333D;
}

.info-point-primary .skilify-info-point-img {
  border: 2px solid #F56962;
}

.info-point-primary .skilify-info-point-img img {
  background-color: #F56962;
}

/*
================================================
5. categories Cards Sec CSS
================================================
*/
.categories-crd {
  padding: 25px;
  height: 100%;
  border-radius: 5px;
  transition: 0.5s;
  display: block;
}

.categories-crd:hover {
  box-shadow: 0 0 30px -15px rgba(0, 0, 0, 0.4);
}

.categories-crd:hover .card-title, .categories-crd:hover .card-text {
  color: #fff;
}

.categories-crd:hover .categories-crd-img-wrap {
  background-color: #fff;
}

.categories-crd-list-v3 .categories-crd {
  padding: 15px;
  border-radius: 10px;
}

.categories-crd-list-v3 .categories-crd img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.categories-crd-img-wrap {
  height: 60px;
  width: 60px;
  display: inline-block;
  border-radius: 5px;
  transition: 0.5s;
}

.categories-crd-gray {
  background-color: #EFEFF6;
}

.categories-crd-gray .categories-crd-img-wrap {
  background-color: #e2ddff;
}

.categories-crd-gray:hover {
  background-color: #5444a5;
}

.categories-crd-orange {
  background-color: #F68C201A;
}

.categories-crd-orange .categories-crd-img-wrap {
  background-color: #fae0d0;
}

.categories-crd-orange:hover {
  background-color: #F68C20;
}

.categories-crd-blue {
  background-color: #BBF0FF80;
}

.categories-crd-blue .categories-crd-img-wrap {
  background-color: #bbf0ff;
}

.categories-crd-blue:hover {
  background-color: #36bbf0;
}

.categories-crd-green {
  background-color: #CEF6D680;
}

.categories-crd-green .categories-crd-img-wrap {
  background-color: #cef6d6;
}

.categories-crd-green:hover {
  background-color: #5fd46d;
}

/*
================================================
6. Multi Course cards Sec CSS
================================================
*/
.multi-course-crd-sec.bg-white .course-card {
  box-shadow: 0 0 30px -15px rgba(0, 0, 0, 0.4);
}

/*
================================================
7. Know About Platform v2 Sec CSS
================================================
*/
.learn-steps-images::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #F56962;
  opacity: 0.4;
  border-radius: 50%;
  height: 350px;
  width: 350px;
}

.learn-step-img-wrap {
  padding: 12px;
  z-index: 2;
  box-shadow: 0 0 30px -10px rgba(0, 0, 0, 0.5);
}

.learn-step-img-wrap img {
  object-fit: cover;
  height: 170px;
  width: 200px;
}

.learn-step-img-wrap span {
  height: 6px;
  width: 100%;
  display: block;
}

/*
================================================
8. Card slider Sec CSS
================================================
*/
/*
================================================
9. Remote Learning Sec CSS
================================================
*/
.remote-learning-sec::before, .remote-learning-sec::after {
  content: "";
  position: absolute;
  width: 110px;
  height: 250px;
}

.remote-learning-sec::before {
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: url(../../assets/images/dots-pattern-gray-vertical.svg) no-repeat;
}

.remote-learning-sec::after {
  bottom: 0;
  left: 0;
  background: url(../../assets/images/dots-pattern-pink.svg) no-repeat;
}

.remote-learning-content {
  z-index: 5;
  max-width: 900px;
  margin: 0 auto;
}

.patterns-wrap img {
  position: absolute;
  z-index: 1;
}

.remote-circle1 {
  top: 0;
  left: 0;
}

.remote-circle2 {
  top: 50%;
  left: 5%;
}

.remote-circle3 {
  top: 50%;
  left: 5%;
  transform: translateY(-50%);
}

.remote-circle4 {
  bottom: 0px;
  left: 15px;
}

.remote-circle5 {
  top: 0px;
  right: 5%;
}

.remote-circle6 {
  top: 0;
  right: -5%;
}

.remote-circle7 {
  top: 40%;
  right: 0;
}

.remote-circle8 {
  bottom: 0px;
  right: 10%;
}

@keyframes swinging {
  0% {
    transform: rotate(-10deg) translate3d(-15px, 0, 0);
  }
  100% {
    transform: rotate(10deg) translate3d(15px, 0, 0);
  }
}

/*
================================================
10. Footer CSS
================================================
*/
.footer-link {
  color: #91929A;
  font-size: 18px;
}

.footer-link:hover {
  color: #fff;
}

.copy-right {
  border-top: 1px solid #91929A;
}

.copy-right .footer-socila-link svg path, .copy-right .footer-socila-link svg circle, .copy-right .footer-socila-link svg rect {
  fill: #777;
}

.copy-right .footer-socila-link a:hover svg path, .copy-right .footer-socila-link a:hover svg circle, .copy-right .footer-socila-link a:hover svg rect {
  fill: #fff;
}

.copy-right-txt {
  color: #91929A;
  font-size: 16px;
}

/*
================================================
10. Apply card Sec CSS
================================================
*/
.apply-crd-sec {
  position: relative;
}

.apply-crd-sec::before, .apply-crd-sec::after {
  content: "";
  position: absolute;
  width: 110px;
  height: 250px;
}

.apply-crd-sec::before {
  top: 0;
  right: 0;
  background: url(../../assets/images/dots-pattern-gray-vertical.svg) no-repeat;
}

.apply-crd-sec::after {
  bottom: 0;
  left: 0;
  background: url(../../assets/images/dots-pattern-pink.svg) no-repeat;
}

.apply-crd-list {
  position: relative;
  z-index: 3;
}

.apply-crd {
  padding: 25px 15px;
  background-color: #fff;
  border-radius: 5px;
  transition: 0.5s;
}

.apply-crd:hover {
  box-shadow: 0 0 35px 1px #f5696270;
}

.apply-crd-img img {
  border-radius: 50%;
}

/*
================================================
4. Contactus Main Sec Css
================================================
*/
.contactus-item .card-text {
  line-height: 1.8;
}

.contactus-item .sec-sub-heading {
  font-size: 18px;
}

.contactus-address-txt {
  font-size: 14px;
}

.bg-primary-after::after,
.bg-purple-after::after,
.bg-green-after::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 65%;
  background-color: #fef0ef;
  z-index: -1;
}

.bg-primary-after .location-svg,
.bg-purple-after .location-svg,
.bg-green-after .location-svg {
  fill: #f56962;
}

.contactus-item-reverse::after {
  left: auto;
  right: 0;
  top: 0;
}

.bg-purple-after::after {
  background-color: #6C63FF1A;
}

.bg-green-after::after {
  background-color: #EFFCFA;
}

/*
================================================
5. Two side curve Sec Css
================================================
*/
.two-side-curve-content {
  border-radius: 0 200px 0 200px;
  padding: 80px;
}

.two-side-curve-content .sec-heading {
  font-weight: 700;
}

/*
================================================
3. Banner CSS
================================================
*/
.contactus-banner::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0px;
  height: 50px;
  width: 100%;
  background-color: #fff;
  z-index: 2;
}

.contactus-banner .banner-content {
  padding-bottom: 60px;
}

.contactus-banner .sub-banner-heading {
  font-size: 18px;
}

.contactus-banner .banner-heading {
  font-size: 40px;
}

.contactus-banner .banner-brief {
  font-size: 20px;
}

.contactus-banner-row {
  z-index: 3;
}

/*
================================================
11. Responsive CSS
================================================
*/
@media only screen and (max-width: 1024px) {
  /** multi choice crd sec CSS Start  **/
  .multi-choice-crd-list {
    grid-template-columns: repeat(3, 1fr);
  }
  /** multi choice crd sec CSS End  **/
  /** teacher profile page css Start **/
  .teacher-profile-banner {
    padding-bottom: 80px;
  }
  .teacher-profile-banner::after {
    height: 0;
  }
  .teacher-profile-banner .profile-banner-img {
    border: 5px solid #fff;
  }
  .teacher-profile-banner .profile-banner-txt {
    padding-bottom: 0;
  }
  /** teacher profile  page css END **/
}

@media only screen and (max-width: 991px) {
  /** Header CSS Start  **/
  .dropdown-toggle.show .dropdown-icon {
    transform: rotate(180deg);
  }
  .navbar-collapse {
    position: fixed;
    width: 400px;
    max-width: 100%;
    top: 75px;
    right: -100%;
    height: 100%;
    min-height: 100vh;
    background-color: #fff;
    overflow-y: scroll;
    transition: 0.3s;
    padding: 20px 15px;
    padding-bottom: 85px;
  }
  .navbar-collapse.show {
    right: 0;
    box-shadow: #f56b64 -10px 21px 50px -25px;
  }
  .header.sticky .navbar-collapse {
    top: 65px;
  }
  .header.sticky .navbar {
    transform: none;
  }
  .header-light .nav-link {
    color: #5F5982;
  }
  .header-light.sticky .header-svg-fill,
  .header-light .header-svg-fill {
    fill: #5F5982;
  }
  .header .nav-link {
    width: 100%;
  }
  .header .nav-item {
    border-bottom: 1px solid #F56962;
  }
  .dropdown-menu.show {
    margin-bottom: 10px;
  }
  /** Header CSS End  **/
  /** Course Detail css Start **/
  #course-detail-banner-bg {
    width: calc(100% + 30px);
    margin-left: -15px;
    height: 100%;
  }
  .course-detail-banner {
    position: relative;
  }
  .course-detail-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0 50px;
    grid-template-rows: max-content;
  }
  .course-detail-crd-btn {
    width: auto;
  }
  /** Course Detail css End **/
  /** Article Card  css Start **/
  .article-crd-list {
    grid-template-columns: repeat(2, 1fr);
  }
  /** Article Card  css End **/
}

@media only screen and (max-width: 768px) {
  /** Common Banner CSS Start  **/
  .banner-heading {
    font-size: 40px;
  }
  .banner-brief {
    font-size: 20px;
  }
  /** Common Banner CSS End  **/
  /** Blog Banner CSS Start  **/
  .blog-banner .banner-content {
    margin: 0  auto;
  }
  /** Blog Banner CSS End  **/
  /** Two side curve Sec css  Start**/
  .two-side-curve-content {
    padding: 50px 15px;
    border-radius: 0 70px 0 70px;
  }
  /** Two side curve Sec css  End**/
  /** Course Detail css Start **/
  .course-detail-crd-btn {
    width: 100%;
  }
  /** Course Detail css End **/
  /** Contact us css Start **/
  .contactus-item .sec-heading {
    font-size: 30px;
  }
  .contactus-item .sec-sub-heading {
    font-size: 16px;
  }
  /** Contact us css End **/
}

@media only screen and (max-width: 767px) {
  html {
    font-size: 70%;
  }
  /** Header Css Start **/
  .navbar-collapse {
    top: 67px;
  }
  .header.sticky .navbar-collapse {
    top: 57px;
  }
  .main-top-gap {
    margin-top: 79px;
  }
  /** Header Css End **/
  /** common code Css Start **/
  .course-card:hover {
    transform: none;
  }
  .banner-form .index-banner-search {
    font-size: 14px;
  }
  /** common code Css End **/
  /** spacing Classes Css Start **/
  .pt-8, .py-8 {
    padding-top: 50px;
  }
  .pb-8, .py-8 {
    padding-bottom: 50px;
  }
  .mt-8, .my-8 {
    margin-top: 50px;
  }
  .mb-8, .my-8 {
    margin-bottom: 50px;
  }
  .readcrumb-sec {
    padding-bottom: 100px;
  }
  .cart-main-content {
    margin-top: -50px;
  }
  .teacher-profile-banner {
    padding-bottom: 50px;
  }
  .skilify-info-sec {
    padding: 0;
  }
  .skilify-info-points {
    top: -50px;
  }
  /** spacing Classes Css End **/
  /** Index Banner Css Start **/
  .index-banner::after,
  .index-banner::before {
    opacity: 0.3;
  }
  /** Index Banner Css End **/
  /** card Css Start **/
  .crd-img {
    height: 260px;
  }
  /** card Css End **/
  /** Article Card  css Start **/
  .article-crd-list {
    grid-template-columns: 1fr;
  }
  /** Article Card  css End **/
  /** Blog Detail css Start **/
  .blog-detail-img img {
    height: auto;
  }
  .blog-detail-banner {
    padding-bottom: 120px;
  }
  .clap-response-wrap {
    max-width: 100%;
  }
  /** Blog Detail css End **/
  /** Courses  page css Start **/
  .courses-search .sec-header .btn-secondary {
    padding: 10px 15px 10px 15px;
  }
  .learn-steps-v3-content::after {
    width: 100%;
    height: 65%;
  }
  /** Courses  css End **/
  /** Contact us css Start **/
  .contactus-banner .banner-content {
    padding-bottom: 20px;
  }
  .contactus-item .sec-heading {
    font-size: 26px;
  }
  .contactus-item::after {
    width: 100%;
  }
  /** Contact us css End **/
}

@media only screen and (max-width: 575px) {
  .main-top-gap {
    margin-top: 77px;
  }
  /** Home 2 Css Start **/
  .hero-banner-v2::after {
    border-radius: 25px;
  }
  /** Home 2 Css Css **/
  /** Common Banner CSS Start  **/
  .banner-heading {
    font-size: 34px !important;
  }
  .banner-brief {
    font-size: 18px !important;
  }
  /** Common Banner CSS End  **/
  /** Course Detail css Start **/
  .course-price {
    width: 100%;
  }
  /** Course Detail css End **/
  /** Cart css Start **/
  .cart-course-img {
    width: 100%;
    height: auto;
  }
  .check-out-crd, .payment-crd-wrap {
    min-width: auto;
  }
  /** Cart css End **/
  /** Card CSS Start  **/
  .card-subtitle {
    font-size: 16px;
  }
  .card-title {
    font-size: 20px;
  }
  .card-text {
    font-size: 14px;
  }
  /** Card CSS End  **/
  /** Remote Learning CSS Start  **/
  .remote-learning-content-wrap {
    padding: 0;
  }
  .remote-learning-sec::before, .remote-learning-sec::after {
    width: 70px;
    height: 100px;
    background-size: contain;
  }
  /** Remote Learning CSS End  **/
  /** Course Card CSS Start  **/
  .course-card {
    padding: 10px;
  }
  .course-card .crd-bottom {
    position: absolute;
    bottom: 10px;
    right: 10px;
    left: 10px;
  }
  .crd-info-txt {
    font-size: 12px;
  }
  /** Course Card CSS End  **/
  /** Common section CSS Start  **/
  .sec-heading {
    font-size: 30px;
  }
  .sec-brief {
    font-size: 16px;
  }
  /** Common section CSS End  **/
  /** Home v3 Css Start **/
  .skilify-info-point img {
    height: 35px;
    width: 35px;
  }
  .skilify-info-point .card-title {
    font-size: 18px;
  }
  /** Home v3 Css End **/
  /** Two Side Curve section CSS Star  **/
  .two-side-curve-content .sec-heading {
    font-size: 24px;
  }
  .two-side-curve-content .sec-brief {
    font-size: 14px;
  }
  /** Two Side Curve section CSS End  **/
  /** Blog Detail CSS Start  **/
  .clap-text,
  .respose-text {
    font-size: 14px;
  }
  .social-btn {
    min-width: 130px;
  }
  .author-writen-by {
    font-size: 12px;
  }
  .author-name {
    font-size: 18px;
  }
  /** Blog Detail css End **/
  /** Contact us css Satrt **/
  .contactus-banner .sub-banner-heading {
    font-size: 16px;
  }
  /** Contact us css End **/
}

@media only screen and (max-width: 480px) {
  .header-logo-text {
    font-size: 24px;
    letter-spacing: 0.01em;
  }
  /** Index Banner CSS Start  **/
  .index-banner {
    padding-top: 100px;
  }
  .banner-form .btn.btn-primary, .banner-form .btn.btn-secondary {
    padding: 10px 15px;
  }
  .banner-form .btn.btn-primary img, .banner-form .btn.btn-secondary img {
    width: 25px;
  }
  /** Index Banner CSS End  **/
  /** Blog Detail CSS Start  **/
  .crd-img.blog-crd-img {
    height: auto;
  }
  /** Blog Detail CSS End  **/
  /** Footer CSS Start  **/
  .footer-heading {
    border-bottom: 1px solid #91929A;
    position: relative;
    font-size: 20px;
  }
  .footer-heading::after, .footer-heading::before {
    content: "";
    transition: 0.3s;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 3px;
    width: 20px;
    border-radius: 10px;
    background-color: #91929A;
  }
  .footer-heading.active::after, .footer-heading.active::before {
    background-color: #fff;
  }
  .footer-heading.active::before {
    transform: translateY(-50%) rotate(270deg);
  }
  .footer-links {
    font-size: 16px;
    transition: 0.3s;
    height: 0;
    overflow: hidden;
  }
  .copy-right {
    border: 0;
  }
  /** Footer CSS End  **/
}
